<template>
  <center-screen>
    <v-form ref="form" v-model="valid">
      <div v-if="!confirmed">
        <p v-if="!isLogin && !getConfirmed && !invalid">
          Thank you <strong>{{ name }}</strong> for signing up with us. We sent
          an SMS with confirmation pin code to your phone number
          <strong>{{ phone }}</strong
          >, please use it to complete your <span v-if="isLogin">login</span
          ><span v-if="!isLogin">registration</span>.
        </p>
        <p v-if="isLogin && !getConfirmed && !invalid">
          We sent an SMS with confirmation pin code to your phone number
          <strong>{{ phone }}</strong
          >, please use it to complete your registration.
        </p>
        <p v-if="invalid">Your confirmation pin is invalid.</p>
        <div v-if="!getConfirmed || isLogin">
          <v-row>
            <v-col>
              <v-text-field
                :label="$t('user.pinCode')"
                prepend-icon="mdi-lock"
                v-model="code"
                :role="roles.pin"
                counter="4"
                type="number"
                @keyup="hitEnter"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <v-btn
                v-if="resendingCountdown === 0"
                color="secondary"
                class="text--primary"
                depressed
                :loading="sending"
                @click="requestPin"
              >
                <v-icon small>mdi-message-processing</v-icon>
                {{ $t("login.resendPin") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="text-center ma-5">
          <v-progress-circular
              v-if="resendingCountdown > 0 && !confriming && !sending"
              :value="resendingCountdown / 60 * 100"
              :rotate="360"
              :size="40"
              color="primary"
            >
              {{ resendingCountdown }}
          </v-progress-circular>

          <v-progress-circular
            v-if="confriming && !sending"
            :indeterminate="confriming"
            :size="40"
            color="primary"
          ></v-progress-circular>
          <v-icon large v-if="invalid" color="red">mdi-alert-circle</v-icon>
        </div>
      </div>

      <p v-if="confirmed" class="primary--text">
        Confirmation has been completed. You will be redirected automatically to
        home screen in a few seconds, or you can click on
        {{ $t("app.route.shopping") }} button.
      </p>
    </v-form>

    <template v-slot:actions>
      <v-btn text color="primary" depressed @click="goToRegister">
        {{ $t("login.register") }}
      </v-btn>
      <v-divider vertical />
      <v-btn text color="primary" depressed @click="goToLogin">
        {{ $t("login.changeNumber") }}
      </v-btn>
      <v-spacer />
      <v-btn
        v-if="!confirmed"
        color="primary"
        tile
        depressed
        @click="tryConfirm"
      >
        {{ isLogin ? $t("login.login") : $t("general.confirm") }}
      </v-btn>
      <v-btn
        v-if="confirmed"
        color="primary"
        tile
        depressed
        @click="$router.replace({ name: 'store' })"
      >
        {{ $t("app.route.shopping") }}
      </v-btn>
    </template>
  </center-screen>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CenterScreen from "@/common/layouts/CenterScreen";

export default {
  name: "Login",
  components: { CenterScreen },
  data() {
    return {
      valid: false,
      redirect: "/",
      phone: null,
      name: "",
      isLogin: false,
      code: null,
      confriming: false,
      confirmed: false,
      sending: false,
      resendingCountdown: 60,
      invalid: false,
      roles: {
        pin: [
          (v) => !!v || "This field is required",
          (v) =>
            (!!v && v.length !== 4) ||
            "Please enter the pin code we sent to your number",
          (v) => {
            const re = /^[0-9]{5}$/;
            return (
              re.test(String(v).toLowerCase()) || "Input should only be numbers"
            );
          },
        ],
      },
    };
  },
  mounted() {
    const { name, phone, code, registrationType, isLogin } = this.$route.query;
    if (!phone) {
      const loginPhone = window.localStorage.getItem("loginPhone");
      const registerPhone = window.localStorage.getItem("registerPhone");

      this.isLogin = !!loginPhone;
      this.registrationType = !!registerPhone ? "phone" : "email";
      this.phone = this.isLogin ? loginPhone : registerPhone;
    } else {
      this.phone = phone;
      this.name = name;
      this.isLogin = isLogin;
    }

    if (code) {
      this.code = code;
    }

    this.startCountdown();
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),

    getConfirmed() {
      const { phoneConfirmed } = this.getCurrentUser;
      return phoneConfirmed;
    },
  },
  methods: {
    ...mapActions("auth", ["login", "requestLoginPin", "logout"]),

    requestPin() {
      this.sending = true;
      this.requestLoginPin(this.phone)
        .then(() => (this.sending = false))
        .catch(() => (this.sending = false));
      this.startCountdown();
    },
    hitEnter(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.tryConfirm();
      }
    },
    tryConfirm() {
      this.$refs.form.validate();

      setTimeout(() => {
        if (this.valid) {
          this.confriming = true;
          this.login({
            type: "pin.login",
            principal: this.phone,
            credential: this.code,
          })
            .then(() => {
              this.confriming = false;
              this.confirmed = true;
              setTimeout(() => {
                this.initTheme();
                this.$router.replace({ name: this.homePage });
              }, 2000);
            })
            .catch(() => (this.confriming = false));
        }
      }, 300);
    },
    async goToLogin() {
      window.localStorage.removeItem("registerPhone");
      window.localStorage.removeItem("loginPhone");
      this.$router.replace({ name: "login" });
    },
    goToRegister() {
      window.localStorage.removeItem("registerPhone");
      window.localStorage.removeItem("loginPhone");
      this.$router.replace({ name: "register" });
    },
    startCountdown() {
      this.resendingCountdown = 60;
      let countdown = setInterval(() => {
        this.resendingCountdown--;
        if (this.resendingCountdown === 0) {
          clearInterval(countdown);
          countdown = null;
        }
      }, 1000);
    },
  },
};
</script>
